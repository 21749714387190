<template>
  <UBadge 
    v-if="typeof count === 'number' && count > 0"
    color="gray"
    size="xs"
  >
      <UIcon :name="icons.opleiding_sm" />
      <span class="ml-1">{{ count }} {{ count === 1 ? 'opleiding' : 'opleidingen' }}</span>
  </UBadge>
</template>

<script lang="ts" setup>
  const props = defineProps<{ count?: number }>()
  const { icons } = useConstants()
</script>

<style>

</style>